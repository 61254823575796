<template>
    <div v-if="showModal" :class="['modal','modal-feedback',showModal?'modal-open':'']" style="display: block" @keyup.esc="onClose">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <button tabindex="0" type="button" class="close" ref="closeBtn" data-dismiss="modal" aria-label="Close" @click="onClose">
                        <span class="material-icons" aria-hidden="true" data-wg-notranslate="">close</span>
                    </button>
                </div>

                <h3 class="modal-content__title">Du hast Feedback oder möchtest uns kontaktieren?</h3>
                <p class="modal-content__info">Dann schreibe uns gern direkt eine Nachricht. Du kannst sie anonym an uns senden oder deine Email angeben, damit wir dir antworten können.</p>
                <div class="feedback">
                    <form action="#!" class="mt30">
                        <div class="form-group mb15">
                            <input type="text" class="form-control" ref="firstFeedbackInput" v-model="data.sender" placeholder="Deine E-Mail:">
                            <div class="email-help help" v-show="errors.sender">GKein gültiges E-Mail Format.</div>
                        </div>
                        <div class="form-group mb15">
                            <textarea class="form-control" v-model="data.message" cols="30" rows="10" placeholder="Ваше повідомлення"></textarea>
                            <div class="message-help help" v-show="errors.message">Введіть повідомлення</div>
                        </div>

                        <checkbox labelText="Ich erkläre mein Einverständnis in die Verarbeitung meiner personenbezogenen Daten. Diese Einwilligung erteile ich freiwillig. Hinweis: Du kannst diese Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Weitere Informationen findest du in den Informationspflichten" errorMessage="Du musst die Datenschutzerklärung akzeptieren." :showError="errors.datenschutz" name="privacy-policy" @checkBoxChanged="checkBoxChanged"></checkbox>

                        <button class="btn btn-block mt30" type="submit" @click="sendMessage">Absenden</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { sendMail } from '@/api/email';
    import { hasErrors } from '@/utils/helpers';
    import { validEmail } from '@/utils/validate';

    export default {
        name: "WBFeedback",
        components: {
            Checkbox: () => import('@/components/controls/Checkbox.vue'),
        },
        props: {
            showModal: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                data: {
                    email_id: 18,
                    message: '',
                    datenschutz: false,
                    sender: "",
                    app: this.$appName,
                    policies: false,
                },
                errors: {
                    datenschutz: false,
                    email: false,
                    message: false
                }
            }
        },
        watch: {
            showModal(newValue){
              this.$nextTick(() => {
                if(newValue == true){
                    this.$refs.closeBtn.focus();
                }
            });
          }
      },
      methods: {
        onClose() {
            this.$emit('on-close');
        },
        checkBoxChanged(value){
            this.data.datenschutz = value;
        },
        sendMessage: function (event){
            event.preventDefault();
            
            if(this.data.sender != "" && !validEmail(this.data.sender)){
                this.errors.sender = true;
            }
            else{
                this.errors.sender = false;
            }

            if(this.data.message==""){
                this.errors.message = true;
            }
            else{
                this.errors.message = false;
            }

            if(this.data.datenschutz==false){
                this.errors.datenschutz = true;
                this.data.policies = false;
            }
            else{
                this.data.policies = true;
                this.errors.datenschutz = false;
            }

            if(!hasErrors(this.errors)){
                sendMail(this.data).then( response => {
                    this.$notify({
                      duration: 3500,
                      title: 'E-Mail gesendet',
                      text: 'Ihre E-Mail wurde versendet!'
                  });

                    this.onClose();

                }).catch(() => {
                    this.$notify({
                        type: 'error',
                        duration: 3500,
                        title: 'Feher!',
                        text: 'Es ist ein Fehler aufgetreten. Ihre E-Mail konnte nicht gesendet werden.'
                    });
                });
            }

        },
    }
};
</script>
<style lang="scss">

    .modal-feedback{
      min-height: 100vh;
      min-height: -webkit-fill-available;

      .modal-content{
        overflow-x: hidden;
        height: -webkit-fill-available;

        .form-group, .form-check{
            display: block;

            .form-control{
                display: block;
            }

            label{
                width: auto!important;
            }

            .help{
                color: red;
                display: block;
                margin-top: 2px;
            }
        }
    }
}
</style>